import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';
import PrimaryCallout from '../components/PrimaryCallout';
import styled from 'styled-components';
import { respondTo } from '../util/breakpoints';
import CTAGrid from '../components/CTAGrid';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `Not Found | KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) | Health Care Professional Resources`,
    keywords: ``,
    description: `The page you are looking for cannot be found. Please check back at a later date or visit other pages about KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib).`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/adjuvant-renal-cell-carcinoma","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠564","description":"Clinical Findings from KEYNOTE⁠-⁠564","trialDesign":"Phase 3, multicenter, randomized, double-blind, placebo-controlled trial in 994 patients.1"}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/adjuvant-renal-cell-carcinoma","@type":"MedicalAudience","audienceType":"health care professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`
    ]
}

const jobCode = jobCodes[0].jobCode;

const StyledCallout = styled(PrimaryCallout)`   
    padding: 60px 0;
    &:before { 
        width: 0;
        border-left: 0;
    }
    ${respondTo.md`
        padding: 100px 0;
    `}
`;

const StyledGrid = styled(CTAGrid)`
    margin: 0 20px 10px 20px;
    ${respondTo.md`
        margin: 0 auto 10px auto;
    `}
`;

const PageNotFoundLayout = styled(DefaultLayout)`
    [class*='CTAGrid__CTAGridContent']{
        padding-bottom: 40px;
    }
    [class*='__PrimaryCalloutContainer']{
        &:hover {
            background: unset;
        }
    }
`;

const GridLinks = [
    {
        title: 'DOSING',
        buttonText: 'See Details',
        buttonTo: '/dosing',
        body: 'Learn about dosing and administration'
    },
    {
        title: 'PATIENT SUPPORT',
        buttonText: 'Learn More',
        buttonTo: '/patient-support',
        body: 'Find information and support resources for patients'
    }
];

const PageNotFound = ({location}) => {
    return (
        <PageNotFoundLayout location={ location } name='404 Error' pageMeta={pageMeta} jobCode={jobCode}>
            <StyledCallout buttonText='View Site Map' buttonTo='/sitemap'>
                <p>The page you are looking for cannot be found.</p>
            </StyledCallout>
            <StyledGrid linkObj={GridLinks}/>
        </PageNotFoundLayout>
    )
};

export default PageNotFound;
