import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { H4, H6 } from '../../util/typography';
import Button from '../Button';
import { Link } from 'gatsby';
import { arrowRightWhite } from '../../util/icons';
import { splitGradient } from '../../util/functions';

const TileContent = styled(Link)`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    border: 1px solid ${colors.pebble};
    border-radius: 6px;
    align-items: center;
    text-align: center;
    width: 100%;
    background: none;
    cursor: pointer;
    text-decoration: none;
    ${respondTo.md`
        padding: 30px 60px;
        gap: 32px;
        flex: 1 0 calc(50% - 60px);
    `}
    &:hover{
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
        [class*='__ButtonContainer']{
            background: ${splitGradient(colors.greenDark, colors.greenRollOver)};
            background-position: right center;
            background-size: 201% 100%;
            background-origin: border-box;
            &:after {
                animation-duration: 0.3s;
                animation-name: arrowRight-animation;
                animation-fill-mode: both;  
            }
        }
    }
    @keyframes arrowRight-animation {
        0% {
            background-position: center 50%, left;
        }

        50% {
            background-position: calc(100% + 20px) center, center;
        }

        50.001% {
            background-position: calc(0% - 20px) center, center;
        }

        100% {
            background-position: center 50%, right;
        }
    }
`;

const TileTitle = styled(H6)`
    color: ${colors.mulberry};
    padding: 10px 0;
    border-top: 1px solid ${colors.brandGreen}; 
    border-bottom: 1px solid ${colors.brandGreen}; 
`;

const TileBody = styled(H4)`
    color: ${colors.ink};
`;

const TileButton = styled(Button)`
    position: relative;
    padding-right: 44px;
    background-image: ${splitGradient(colors.greenDark, colors.greenRollOver)};
    background-size: 201% 100%;
    background-origin: border-box;
    transition: background-position 0.3s ease-in-out;
    &:after {
        content: '';
        position: absolute;
        right: 16px;
        background: url(${arrowRightWhite});
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-position: center left;
    }
`;

const CTAGridContent = styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        ${respondTo.md`
            flex-direction: row;
            justify-content: center;
            padding: 0 30px;
            max-width: 1032px;
            margin: 0 auto;
        `}
`;

const CTAGrid = ({className, linkObj}) => {
    return (
        <CTAGridContent className={className}>
            {Array.isArray(linkObj) && linkObj.map((link) => (
                <TileContent to={link.buttonTo}>
                    {link.title && <TileTitle>{link.title}</TileTitle>}
                    {link.body && <TileBody>{link.body}</TileBody>}
                    {link.buttonText && <TileButton type='primary-dark' noIcon>{link.buttonText}</TileButton>}
                </TileContent>
            ))}
        </CTAGridContent>
)};
export default CTAGrid;
